import { FetcherWithComponents, useFetcher } from '@remix-run/react';
import { useCallback, useEffect, useRef } from 'react';
import { SubmitOptions } from 'react-router-dom/dist/dom';
import { fetcherType } from '@common/fetch.utils';
import { transferFormData } from '@common/tranfer.utils';
import { ApiResponseMessage } from '@server/models/common.models';
import { useLoadingStore } from '@store/loading.store';

type useCustomFetcherSubmitProps = {
    action?: string;
    actionKey?: string;
    download?: boolean;
};

export function useCustomFetcherSubmit<T extends Record<string, any>>({
    action = '',
    actionKey = 'default',
    download,
}: useCustomFetcherSubmitProps = {}) {
    const { changeLoading } = useLoadingStore((state) => state);
    const resolveRef = useRef<any>();
    const promiseRef = useRef<Promise<any>>();
    const fetcher = useFetcher<T>();

    const submitOptions = getSubmitOptions(action);
    const downloadOptions = getDownloadSubmitOptions(action);
    const multipartOptions = getSubmitMultiPartOptions(action);

    if (!promiseRef.current) {
        promiseRef.current = new Promise((resolve) => {
            resolveRef.current = resolve;
        });
    }

    const resetResolver = useCallback(() => {
        promiseRef.current = new Promise((resolve) => {
            resolveRef.current = resolve;
        });
    }, []);

    const submit = useCallback(
        async (data: T | null, options?: SubmitOptions) => {
            const option = options ? options : download ? downloadOptions : submitOptions;
            fetcher.submit(
                {
                    ...(data ? data : {}),
                    _action: actionKey,
                },
                option,
            );
            return promiseRef.current;
        },
        [fetcher, promiseRef, actionKey, download, downloadOptions, submitOptions],
    );

    const multipartSubmit = useCallback(
        async (data: T, submitActionKey?: string, options?: SubmitOptions) => {
            const formData = data instanceof FormData ? data : transferFormData(data);

            formData.append('_action', submitActionKey ? submitActionKey : actionKey);
            fetcher.submit(formData, {
                ...multipartOptions,
                ...options,
            });

            return promiseRef.current;
        },
        [fetcher, promiseRef, actionKey, download, downloadOptions, submitOptions],
    );

    useEffect(() => {
        if (
            fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>) === 'loaderSubmission' ||
            fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>) === 'actionSubmission'
        ) {
            changeLoading(true);
        } else if (
            fetcher &&
            (fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>) === 'success' ||
                fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>) === 'done' ||
                fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>) === 'serverError')
        ) {
            resolveRef.current(fetcher.data);
            resetResolver();
            changeLoading(false);
        } else {
            //console.log(fetcher);
            //console.log(fetcherType(fetcher as FetcherWithComponents<ApiResponseMessage>));
            changeLoading(false);
        }
    }, [fetcher, resetResolver]);

    return {
        fetcher,
        submit,
        submitOptions: download ? downloadOptions : submitOptions,
        multipartSubmit,
    };
}

const getSubmitOptions = (action: string): SubmitOptions => {
    return {
        method: 'post',
        action: action,
        encType: 'application/json',
        navigate: false,
    };
};

const getDownloadSubmitOptions = (action: string): SubmitOptions => {
    return {
        method: 'get',
        action: action,
        navigate: false,
    };
};

const getSubmitMultiPartOptions = (action: string): SubmitOptions => {
    return {
        method: 'post',
        action: action,
        encType: 'multipart/form-data',
        navigate: false,
    };
};
